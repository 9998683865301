.base-space {
        margin-top: -100px;
        width: 100%;
        border: 1px solid #E5E7EB;
        box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);
        border-radius: 8px;
}
.base-space .ant-space-item {
        width: 100%;
}

.tags-space {
        padding: 8px;
}

.tags-space .ant-space-item {
        width: fit-content;
}
.tags-space .ant-space-item .ant-tag {
        margin-bottom: 8px;
        padding: 8px;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        color: #2563EB;
        background-color: #DBEAFE;
        border: none;
}
.tags-space .ant-space-item .ant-tag svg {
        color: #2563EB;
}