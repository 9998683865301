.client-info-div .ant-tabs-nav {
        margin-bottom: -7px;
}

.client-info-div .content-body {
        padding: 24px;
}

/* mobile view */

@media screen and (max-width: 767px) {
        .add-new-case-div .form-div {
                width: 100%;
        }

        .add-new-case-div .form-card .ant-input-affix-wrapper .ant-input-prefix {
                padding: 0 10px;
        }

        .add-new-case-div .logo-input-div .ant-upload-wrapper {
                display: block;
        }

        .add-new-case-div .logo-input-div .ant-upload-list-item-container {
                margin: 0;
                margin-top: 10px;
        }

        .add-new-case-div .solutiontype-radio-group {
                display: block;
        }

        .add-new-case-div .solutiontype-radio-group .solutiontype-radio {
                margin: 0;
                margin-bottom: 24px;
                width: 100%;
        }
        .add-new-case-div .solutiontype-radio-group .solutiontype-radio:last-child {
                margin: 0;
        }

        .add-new-case-div .plantype-radio-group .plantype-radio.ant-radio-wrapper {
                padding: 10px;
        }
        .add-new-case-div .plantype-radio .top-section .title {
                font-size: 14px;
                font-weight: 500;
        }
        .add-new-case-div .plantype-radio .top-section .price {
                font-size: 12px;
        }
        .add-new-case-div .plantype-radio .bottom-section.open {
                margin-left: -20px;
                height: 280px;
        }

        .add-new-case-div .plantype-radio .bottom-section .bottom .ant-row {
                width: 100%;
                border: none;
        }
        .add-new-case-div .plantype-radio .bottom-section .bottom .ant-row:nth-child(4), .add-new-case-div .plantype-radio .bottom-section .bottom .ant-row:nth-child(5), .add-new-case-div .plantype-radio .bottom-section .bottom .ant-row:nth-child(6),
        .add-new-case-div .plantype-radio .bottom-section .bottom .ant-row:nth-child(7), .add-new-case-div .plantype-radio .bottom-section .bottom .ant-row:nth-child(8) {
                padding-left: 0;
        }
}