.fna-div .fna-card {
        background-color: #F6F6F6;
        position: relative;
        border: none;
}
.fna-div .wrapper-div {
        margin-left: auto;
        margin-right: auto;
        width: 80%;
}
.fna-div .fna-card .ant-card-body {
        background-color: inherit;
        border-radius: 16px;
}
.fna-div .fna-card .row-one {
        margin-bottom: 60px;
}
.fna-div .fna-card .row-two {
        margin-bottom: 40px;
}
.fna-div .fna-card .row-two .ant-card-body {
        height: 80px;
}
.fna-div .fna-card .row-three {
        margin-bottom: 80px;
}
.fna-div .fna-card .row-three .ant-card-body {
        height: 160px;
}
.fna-div .fna-card .row-four .ant-card-body {
        height: 250px;
}

.fna-div .backdrop-div {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #0B101B99;
        z-index: 1;
        backdrop-filter: blur(2px);
        border-radius: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
}
.fna-div .backdrop-div .card {
        padding: 40px 24px;
        width: 400px;
        text-align: center;
        background-color: #FFFFFF;
        border-radius: 14px;
}
.fna-div .backdrop-div .card .card-title {
        margin: 8px 0 24px 0;
        color: #0B101B;
        font-size: 24px;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: -0.2px;
}
.fna-div .backdrop-div .card button {
        width: 100px;
}