.seller-intel-div .seller-intel-card {
        background-color: #F6F6F6;
        overflow: hidden;
}
.seller-intel-div .seller-intel-list-card {
        background-color: #FFFFFF;
        overflow: hidden;
}
.seller-intel-div .seller-intel-card .ant-card-body {
        padding: 0 !important;
}

.seller-intel-div .intel-row {
        padding: 24px;
}

.seller-intel-div .form-col {
        padding: 24px 16px;
        background-color: #FFFFFF;
}

.seller-intel-div .form-col .input-div.two {
        margin-top: 32px;
}

.seller-intel-div .form-col .input-div .label {
        margin-bottom: 16px;
        color: #374151;
        font-size: 14px;
        font-weight: 600;
        line-height: 16px;
        display: flex;
        align-items: center;
}
.seller-intel-div .form-col .input-div .label svg {
        margin-left: 6px;
        cursor: pointer;
}
.seller-intel-div .form-col .input-div .label svg path {
        fill: #374151;
}

.seller-intel-div .form-col .input-div .ant-slider-track {
        background-color: #144EE3;
}
.seller-intel-div .form-col .input-div .ant-slider-dot-active {
        border-color: #144EE3;
}
.seller-intel-div .form-col .input-div .ant-slider-handle::after {
        box-shadow: 0 0 0 2px #144EE3;
}

.cluster-card .ant-card-body .col-div.chart {
        height: 350px
}

.seller-intel-div .cluster-card .row-div {
        padding: 24px;
        display: flex;
}
.seller-intel-div .cluster-card .row-div .col-div {
        width: 50%;
}
.seller-intel-div .cluster-card .row-div .col-div.text {
        margin-left: 24px;
        display: flex;
        align-items: center;
}

.seller-intel-div .cluster-card .row-div .col-div.text .metadata-card {
        padding: 24px;
        background-color: #F8FAFD;
        width: 100%;
}

.seller-intel-div .cluster-card .row-div .col-div.text .metadata-card .card-title {
        margin-bottom: 24px;
        color:#374151;
        font-size: 18px;
        font-weight: 700;
        line-height: 150%;
}

.seller-intel-div .cluster-card .row-div .col-div.text .metadata-card ul {
        list-style: none;
}
.seller-intel-div .cluster-card .row-div .col-div.text .metadata-card ul li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 34px;
}
.seller-intel-div .cluster-card .row-div .col-div.text .metadata-card ul li p {
        color:#374151;
        font-size: 14px;
        font-family: Inter;
        line-height: 140%;
}
.seller-intel-div .cluster-card .row-div .col-div.text .metadata-card ul li p.value {
        font-weight: 600;
}

.seller-intel-div .tag {
        padding: 4px 12px;
        background-color: #F6F6F6;
        border-radius: 40px;
        color: #374151;
        font-size: 14px;
        font-weight: 500;
        text-transform: capitalize;
}
.seller-intel-div .tag.pending, .seller-intel-div .tag.detected {
        color: #144EE3;
        background-color: #DBEAFE;
}
.seller-intel-div .tag.reported {
        color: #D97706;
        background-color: #FEF3C7;
}
.seller-intel-div .tag.verified {
        color: #18CA95;
        background-color: #EEF9F6;
}
.seller-intel-div .tag.unverified {
        color: #E11D48;
        background-color: #FFE4E6;
}

.seller-intel-card .status-tab-btn-div .ant-tabs-nav {
        margin: 24px 0 0 0;
}

.levels-modal .level-div {
        margin-bottom: 16px;
}
.levels-modal hr {
        margin: 0;
        border: none;
        height: 1px;
        background: lightgray;
        margin-bottom: 16px;
}
.levels-modal .header {
        margin-bottom: 8px;
        font-size: 16px;
        font-weight: 600;
}
.levels-modal .level-div .title {
        font-size: 14px;
        font-weight: 600;
}

/* Mobile view */

@media screen and (max-width: 767px) {
        .seller-intel-div .form-col {
                margin-bottom: 24px;
        }
        .seller-intel-div .cluster-card .row-div {
                flex-wrap: wrap;
        }
        .seller-intel-div .cluster-card .row-div .col-div {
                width: 100%;
        }
        .seller-intel-div .cluster-card .row-div .col-div {
                width: 100%;
        }
        .seller-intel-div .cluster-card .row-div .col-div.text {
                margin-left: 0;
        }
}