.reports-form-card {
        margin: 0 auto;
        width: 700px;
}

.reports-form-card .ant-picker-range {
        padding: 8px 12px;
        border-radius: 8px;
}

.reports-form-card .button-div {
        margin: 16px 0 0 0;
        text-align: right;
}

/* export  */

.reports-div .inner-card .card-title {
        font-weight: 700;
        font-size: 18px;
        line-height: 150%;
        color: #374151;
}

.reports-div .left-col {
        padding-right: 36px;
        border-right: 1px dashed #D9D9D9;
}
.reports-div .right-col {
        padding-left: 36px;
}

.reports-div .right-col ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
}
.reports-div .right-col ul li {
        margin-bottom: 36px;
        display: flex;
        align-items: flex-start;
}
.reports-div .right-col ul li svg {
        margin-right: 12px;
}
.reports-div .title {
        margin-bottom: 4px;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #0B101B;
}
.reports-div .desc {
        font-weight: 400;
        font-size: 16px;
        line-height: 140%;
}
.reports-div .desc .color {
        font-weight: 600;
        text-transform: capitalize;
}
.reports-div .desc .color.red,
.reports-div .left-col .indicator-title.red {
        color: #DC2B2B;
}
.reports-div .desc .color.green,
.reports-div .left-col .indicator-title.green {
        color: #0AA677;
}
.reports-div .desc .color.yellow,
.reports-div .left-col .indicator-title.yellow {
        color: #CA8A04;
}

.reports-div .left-col .title {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #374151;
}
.reports-div .left-col .indicator-title {
        margin: 8px 0 40px 0;
        font-weight: 700;
        font-size: 21px;
        line-height: 100%;
        color: #374151;
        text-transform: capitalize;
}

.reports-div .left-col .bar-div {
        height: 12px;
        background-color: #E8EAF0;
        border-radius: 100px;
        position: relative;
}
.reports-div .left-col .bar-div .base {
        width: 100%;
        height: 100%;
        background-color: #E8EAF0;
        border-radius: 100px;
}

.reports-div .left-col .bar-div .percent {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        border-radius: 100px;
}

.reports-div .left-col .bar-div .percent.red {
        background-color: #DC2B2B;
}
.reports-div .left-col .bar-div .percent.yellow {
        background-color: #CA8A04;
}
.reports-div .left-col .bar-div .percent.green {
        background-color: #0AA677;
}

.reports-div .left-col .bar-div .number {
        height: 40px;
        width: 40px;
        border-radius: 100%;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 800;
        font-size: 16px;
        color: #374151;
        background-color: #FFFFFF;
        position: absolute;
        top: 0;
        transform: translate(-50%, -40%);
}
.reports-div .left-col .bar-div .number span {
        font-size: 10px;
        color: #0B101B66;
}

.reports-div .left-col p.info {
        margin-top: 32px;
        font-size: 14px;
        line-height: 20px;
        color: #144EE3;
        cursor: pointer;
}

.reports-div .button-div {
        margin-top: 24px;
        display: flex;
        justify-content: flex-end;
}

.reports-div .four-col-row {
        margin: 24px 0;
}

.reports-div .four-col-row .inner-card .label {
        margin-bottom: 16px;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #374151;
}

.reports-div .four-col-row .inner-card .bottom-section {
        display: flex;
        align-items: center;
        justify-content: space-between;
}
.reports-div .four-col-row .inner-card .bottom-section .value {
        font-weight: 600;
        font-size: 21px;
        color: #374151;
}

.reports-div .four-col-row .inner-card .bottom-section .diff {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 4px 8px 4px 6px;
        gap: 4px;
        font-weight: 600;
        font-size: 12px;
        color: #374151;
        background: #E8EAF0;
        border-radius: 4px; 
}

.reports-div .four-col-row .inner-card .bottom-section .diff.detected {
        color: #F67906;
        background: #FFE7CD;
}
.reports-div .four-col-row .inner-card .bottom-section .diff.detected svg path {
        stroke: #F67906;
}
.reports-div .four-col-row .inner-card .bottom-section .diff.flagged {
        color: #DC2B2B;
        background: #fdeaec;
}
.reports-div .four-col-row .inner-card .bottom-section .diff.flagged svg path {
        stroke: #db2b2b
}
.reports-div .four-col-row .inner-card .bottom-section .diff.removed {
        color: #895302;
        background: #ECE6DC;
}
.reports-div .four-col-row .inner-card .bottom-section .diff.removed svg path {
        stroke: #895302
}
.reports-div .four-col-row .inner-card .bottom-section .diff.saved {
        color: #0AA677;
        background: #EEF9F6;
}

/* .reports-div .inner-card.second .map-svg {
        margin: -30px 20px -30px -50px;
} */
.reports-div .inner-card.second .map-svg .rsm-marker {
        cursor: pointer;
}
.reports-div .inner-card.second .map-svg .rsm-marker circle {
        fill: #144EE3;
}
.reports-div .inner-card.second .map-svg .rsm-marker text {
        font-weight: 500;
        color: #374151;
        display: none;
}

.reports-div .inner-card.second .map-svg .rsm-marker:hover text {
        display: inherit;
}

.reports-div .chart .map-div {
        height: 100%;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
}
.reports-div .chart .map-div, .platform-modal .chart .map-div {
        width: 100%;
}
.reports-div .chart .map-div .map-item {
        margin-bottom: 24px;
}
.platform-modal .chart .map-div .map-item {
        margin-bottom: 16px;
}
.reports-div .chart .map-div .map-item .label, .platform-modal .chart .map-div .map-item .label {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #374151;
        text-transform: capitalize;
        display: flex;
        align-items: center;
        cursor: pointer;
}
.reports-div .chart .map-div .map-item .label .label-img, .platform-modal .chart .map-div .map-item .label .label-img {
        margin-right: 8px;
        width: 20px;
        height: 20px;
        border-radius: 4px;
}
.reports-div .chart .map-div .map-item .progress-base, .platform-modal .chart .map-div .map-item .progress-base {
        display: flex;
        align-items: center;
}
.reports-div .chart .map-div .map-item .progress-base .base-line, .platform-modal .chart .map-div .map-item .progress-base .base-line {
        width: 93.5%;
        border-radius: 100px;
        background-color: #E8EAF0;
}
.reports-div .chart .map-div .map-item .progress-base .line, .platform-modal .chart .map-div .map-item .progress-base .line {
        height: 8px;
        border-radius: 100px;
        background-color: #144EE3;
}
.reports-div .chart .map-div .map-item .progress-base .value, .platform-modal .chart .map-div .map-item .progress-base .value {
        margin-left: 8px;
        font-weight: 400;
        width: 60px;
        font-size: 14px;
        line-height: 20px;
        color: #374151;
}

.reports-div .markers {
        margin-top: 20px;
        padding-top: 6px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top: 1px solid #E6E6E6;
}

/* mobile view */
@media screen and (max-width: 767px) {
        .reports-form-card {
                width: 100%;
        }
        .reports-form-card .button-div {
                text-align: center;
        }
}