.table {
        margin-top: 28px;
}

.pagination-div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 28px
}

.pagination-div .pagination li {
        border-radius: 100px;
}
.pagination-div .pagination .ant-pagination-item-active {
        background-color: #144EE3;
}
.pagination-div .pagination .ant-pagination-item-active a {
        color: #FFFFFF;
}
.pagination-div .pagination .ant-pagination-next,
.pagination-div .pagination .ant-pagination-prev {
        background: #FFFFFF;
        border: 1px solid #E5E7EB;
        box-shadow: 0px 1px 2px rgba(31, 41, 55, 0.08);
        border-radius: 100px;
        overflow: hidden;
}

.pagination-div .row-select-div .row-select {
        margin-left: 16px;
        width: 110px;
}