.logo-input-div .upload .ant-upload-list {
        display: none !important;
}

.logo-input-div .icon-file-info {
        margin-top: 8px;
        padding: 4px 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 8px;
        border: 1px dashed lightgrey;
}