.case-info-div .add-rule-card .ant-card-body {
        padding: 24px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
}
.case-info-div .add-rule-card .ant-card-body .desc {
        margin: 10px 0 20px 0;
        color: #6B7280;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
}
.case-info-div .add-rule-card .ant-card-body button {
        height: 40px;
        padding: 0 16px;
}

.assets-collapse input {
        height: 40px !important;
}

.subcat-modal.non-editable .ant-modal-footer {
        display: none;
}


/* mobile view */
@media screen and (max-width: 767px) {
        .add-new-case-div .document-card .file-details {
                flex-wrap: wrap;
        }
}