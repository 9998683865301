/* disable popover on hover */
.ant-menu-inline-collapsed-tooltip { display: none; }

.base-layout {
        height: 100vh;
        overflow: hidden;
}
.base-layout .sidebar {
        background-color: #0B101B;
        background-image: url(../../assets/images/bg.svg);
        background-position: center center;
        background-size: 400%;
        background-repeat: no-repeat;
        position: relative;
}
.base-layout .sidebar.collapsed {
        background-size: 1000%;
}
.base-layout .sidebar .logo-div {
        padding: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
}
.base-layout .sidebar.collapsed .logo-div {
        padding: 20px 0;
}
.base-layout .sidebar.collapsed .logo-div svg {
        margin-left: auto;
        margin-right: 12px;
}
.base-layout .sidebar ul {
        padding: 0 15px;
        background: transparent;
}
.base-layout .sidebar .menu-title {
        margin: 12px 0 12px 5px;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        text-transform: uppercase;
        color: #9E9E9E;
}
.base-layout .sidebar ul li {
        padding-left: 14px !important;
}
.base-layout .sidebar ul li a {
        color: #FFFFFF;
        display: flex;
        align-items: center;
}
.base-layout .sidebar ul li a .label {
        font-weight: 500;
        font-size: 14px;
}
.base-layout .sidebar.collapsed ul li a {
        display: block;
}
.base-layout .sidebar ul li a svg {
        margin-right: 16px;
}
.base-layout .sidebar ul li a:hover {
        color: #FFFFFF;
}
.base-layout .sidebar ul li.ant-menu-item-selected {
        background-color: #FFFFFF33;
}

.base-layout .content-layout {
        padding: 24px;
        background-color: #0B101B;
        color: #FFFFFF;
        overflow-y: auto;
}

.base-layout .sidebar .profile-link {
        margin: 0 0 0 16px;
        padding: 16px 0;
        border-top: 1px solid #E6E6E6;
        color: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: absolute;
        bottom: 0;
        width: 98%;
        background-color: black;
}
.base-layout .sidebar .profile-link .left-col {
        display: flex;
        align-items: center;
}
.base-layout .sidebar .profile-link .left-col .img-div {
        margin-right: 12px;
        height: 40px;
        width: 40px;
        background-color: #E6E6E6;
        border-radius: 100%;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        font-weight: 600;
        color: black;
}
.base-layout .sidebar .profile-link .left-col .img-div .profile-img {
        height: 40px;
        width: 40px;
        object-fit: cover;
}
.base-layout .sidebar .profile-link .left-col .text-div .name {
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        letter-spacing: -0.1px;
        text-transform: capitalize;
        max-width: 180px;
        word-wrap: break-word;
}

.base-layout .sidebar .profile-link .left-col .text-div .email {
        font-weight: 500;
        font-size: 13px;
        line-height: 14px;
        letter-spacing: -0.3px;
        color: #9E9E9E;
}

.base-layout .sidebar .client-logo-div {
        display: flex;
        align-items: center;
        color: #FFF;
}
.base-layout .sidebar .client-logo-div img.client-logo {
        margin-right: 8px;
        border-radius: 8px;
}
.base-layout .sidebar .client-logo-div .name {
        font-size: 14px;
        font-weight: 600;
        line-height: 1.5;
}
.base-layout .sidebar .client-logo-div .email {
        font-size: 12px;
        color: #9E9E9E;
        line-height: 1.5;
}

.menu-list-item.overflowed, .menu-list-item.overflowed-si {
        overflow: visible;
}

.menu-list-item.overflowed sup {
        right: 0 !important;
}
.menu-list-item.overflowed-si sup {
        padding: 1px 4px;
        margin-left: -19px !important;
}

.menu-list-item.overflowed .notification-badge {
        margin-left: -16px;
}
 
/* mobile view */

@media screen and (max-width: 767px) {

        .base-layout .mobile-header-card .ant-card-body {
                display: flex;
                align-items: center;
                justify-content: space-between;
        }
        .base-layout .mobile-header-card svg.logo {
                margin-right: auto;
                width: 50%;
        }
        .base-layout .client-logo-div, .mobile-drawer .client-logo-div {
                margin-right: auto;
                display: flex;
                align-items: center;
        }
        .base-layout .client-logo-div .name, .mobile-drawer .client-logo-div .name {
                margin-left: 8px;
                font-weight: 500;
        }
        .mobile-drawer .client-logo-div .name {
                color: #FFFFFF;
        }
        .base-layout .mobile-header-card svg.logo path {
                fill: #144EE3;
        }

        .mobile-drawer {
                background-color: #0B101B !important;
                background-image: url(../../assets/images/bg.svg) !important;
                background-position: center center !important;
                background-size: 200% !important;
                background-repeat: no-repeat !important;
                position: relative !important;
        }
        .mobile-drawer .logo-div {
                margin-bottom: 24px;
                display: flex;
                align-items: center;
                justify-content: space-between;
        }
        .mobile-drawer ul.menu-list {
                color: white;
                background-color: initial;
                margin-bottom: 60px;
        }
        .mobile-drawer ul.menu-list .menu-title {
                margin: 24px 4px;
        }
        .mobile-drawer ul.menu-list .menu-list-item {
                padding: 16px !important;
        }
        .mobile-drawer ul.menu-list .menu-list-item a{
                display: flex;
                align-items: center;
        }
        .mobile-drawer ul.menu-list .menu-list-item a .label {
                margin-left: 8px;
        }

        .mobile-drawer .profile-link {
                position: absolute;
                display: flex;
                align-items: center;
                justify-content: space-between;
                color: white;
                width: 89%;
                bottom: 0px;
                padding: 8px 0;
                background-color: #0B101B;
        }
        .mobile-drawer .profile-link .left-col {
                display: flex;
                align-items: center;
        }
        .mobile-drawer .profile-link .text-div {
                margin-left: 8px;
        }
        .mobile-drawer .profile-link .text-div .name {
                font-weight: 600;
        }
        .mobile-drawer .profile-link .text-div .email {
                margin-top: 2px;
                font-size: 12px;
        }

}