.domain-management-div .cards-row-div .card {
  border: 1px solid rgba(231, 234, 243, 0.7);
  box-shadow: 0px 6px 12px rgba(140, 152, 164, 0.075);
  border-radius: 12px;
  transition: all 0.2s ease-in-out;
}
.domain-management-div .cards-row-div .card .title {
  margin-bottom: 12px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #374151;
}
.domain-management-div .cards-row-div .card .numbers {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.domain-management-div .cards-row-div .card .numbers .value {
  font-weight: 600;
  font-size: 21px;
  line-height: 21px;
  color: #374151;
}
.domain-management-div .cards-row-div .card .numbers .percent {
  padding: 4px 8px 4px 8px;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #374151;
  background: #e7eaf3b3;
  border-radius: 4px;
}
.domain-management-div .cards-row-div .card .numbers .percent.bad {
  color: #dd2c2c;
  background: #fdeaeb;
}
.domain-management-div .cards-row-div .card .numbers .percent.good {
  color: #0aa677;
  background: #eef9f6;
}

.domain-management-div .cards-row-div .card:hover {
  background-color: #144ee3;
}
.domain-management-div .cards-row-div .card:hover .title,
.domain-management-div .cards-row-div .card:hover .numbers .value {
  color: #ffffff;
}
.logo-img-domain-management {
  margin-right: 12px;
  border: 1px solid rgba(125, 131, 152, 0.3);
  border-radius: 4px;
}


/* mobile view */

@media screen and (max-width: 767px) {
  .cards-row-div .ant-space-item {
    width: 100%;
  }
  .cards-row-div .select-input-div {
    width: fit-content;
  }
  .cards-row-div .export-btn {
    margin-top: -42px;
  }
}