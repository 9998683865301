.visual-search-card .loading-div {
        height: 70vh;
        display: flex;
        align-items: center;
        justify-content: center;
}

.visual-search-card .empty-list-div {
        height: 60vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
}
.visual-search-card .empty-list-div .title {
        margin: 24px 0 8px 0;
        font-weight: 600;
        font-size: 18px;
        line-height: 150%;
        color: #374151;
}

.visual-search-card .empty-list-div .desc {
        margin-bottom: 24px;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #374151;
}

.visual-search-card .add-img-btn {
        width: 200px;
        height: 48px;
        font-weight: 500;
        font-size: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
}
.visual-search-card .add-img-btn svg {
        margin-right: 12px;
}

.visual-search-card .page-sub-title {
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        color: #374151;
}

.visual-search-card .table .list-img {
        width: 150px;
        min-height: 100px;
        object-fit: cover;
        border-radius: 4px;
}

.dragger .ant-upload {
        padding: 24px;
}
.dragger .title {
        margin: 16px 0;
        font-weight: 600;
        font-size: 18px;
        line-height: 150%;
        color: #374151;
}
.dragger .desc, .dragger .extra {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #6B7280;
}
.dragger .extra {
        margin: 8px;
}
.dragger .link {
        font-weight: 600;
        font-size: 18px;
        line-height: 150%;
        text-decoration-line: underline;
        color: #144EE3;
}

.dragger .ant-upload-list-item {
        border: 1px dashed #E5E7EB;
        border-radius: 8px;
        height: 40px !important;
}
.dragger .ant-upload-list-item .ant-upload-icon {
        display: none;
}
.dragger .ant-upload-list-item .ant-upload-list-item-progress {
        display: none;
}