.cases-card {
        min-height: calc(100vh - 48px);
}
.select-input-div {
        padding-left: 12px;
        border: 1px solid #E6E6E6;
        border-radius: 8px;
}

.select-input-div label {
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        text-align: right;
        letter-spacing: -0.1px;
        color: #0B101B;
}

.logo-img {
        margin-right: 12px;
        border: 1px solid rgba(125, 131, 152, 0.3);
        border-radius: 4px;
}
.no-logo-div {
        margin-right: 12px;
        height: 40px;
        width: 40px;
        background-color: #F8FAFD;
        border: 1px solid rgba(125, 131, 152, 0.3);
        border-radius: 4px;
}

.case-options-modal .ant-modal-title {
        font-size: 18px;
}

.case-options-modal button.option-btn {
        margin-top: 20px;
        width: 100%;
        height: 64px;
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 400;
}
.case-options-modal button svg {
        margin-right: 14px;
}

.navigate-link {
        color: #275CEF;
        cursor: pointer;
}

.delete-confirm-modal .ant-modal-body {
        text-align: center;
}
.delete-confirm-modal .ant-modal-body .delete-icon {
        margin: 16px 0;
}
.delete-confirm-modal .ant-modal-body .sub-title {
        margin-bottom: 16px;
        font-weight: 700;
        font-size: 24px;
        color: #0B101B;
}
.delete-confirm-modal .ant-modal-body .btn {
        width: calc(50% - 12px);
}
.delete-confirm-modal .ant-modal-body .btn.confirm-btn {
        margin-left: 24px;
}

.add-investigator-link {
        color: #275CEF;
        cursor: pointer; 
}


/* mobile view */

@media screen and (max-width: 767px) {
        .cases-div .filter-space .ant-space-item {
                width: 100%;
        }
}