.login-page {
        height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
}
.login-page .logo {
        margin-bottom: 50px;
}
.login-page .base-div {
        /* width: 920px; */
        width: 500px;
        background-color: white;
        border-radius: 6px;
}
.login-page .base-div .row {
        height: 100%;
}
.login-page .base-div .form-col {
        padding: 44px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
}
.login-page .base-div .form-col .title {
        margin-bottom: 24px;
        margin-right: auto;
        width: 100%;
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #374151;
}
.login-page .base-div .form-col .form {
        width: 100%;
}
.login-page .base-div .form-col .form .ant-form-item {
        margin-bottom: 18px;
}
.login-page .base-div .form-col .form label {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        display: flex;
        align-items: center;
        color: #6B7280;
}
.login-page .base-div .form-col .form label::before {
        display: none;
}
.login-page .base-div .form-col .form input {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        display: flex;
        align-items: center;
        color: #374151;
}
.login-page .base-div .form-col .form .ant-form-item-explain-error {
        font-size: 11px;
}
.login-page .base-div .form-col .form .forgot-password-link {
        margin-left: auto;
        margin-bottom: 16px;
        width: fit-content;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        display: flex;
        align-items: center;
        text-align: right;
        color: #113CFC;
}
.login-page .base-div .form-col .form .submit-btn {
        font-weight: 500;
        font-size: 14px;
        background-color: #144EE3;
}

.login-page .base-div .color-col {
        /* background-color: #144EE3; */
        background: url(../../assets/images/login-bg-box.svg);
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
        background-size: auto;
        background-position: center;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 44px;
}
.login-page .base-div .color-col .color-col-img {
        width: 100%;
}

@media screen and (max-width: 767px) {
       .login-page .base-div {
                width: 90%;
       }
       .login-page .base-div .form-col {
                padding: 24px;
       }
}