.dashboard-div .loading-div {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
}

.dashboard-div .inner-card {
        margin-top: 24px;
        box-shadow: 0px 6px 12px rgba(140, 152, 164, 0.075);
}

.dashboard-div .inner-card.first .left-col {
        padding-right: 36px;
        border-right: 1px dashed #D9D9D9;
}
.dashboard-div .inner-card.first .right-col {
        padding-left: 36px;
}

.dashboard-div .inner-card.first .right-col ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
}
.dashboard-div .inner-card.first .right-col ul li {
        margin-bottom: 36px;
        display: flex;
        align-items: flex-start;
}
.dashboard-div .inner-card.first .right-col ul li svg {
        margin-right: 12px;
}
.dashboard-div .inner-card.first .title {
        margin-bottom: 4px;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #0B101B;
}
.dashboard-div .inner-card.first .desc {
        font-weight: 400;
        font-size: 16px;
        line-height: 140%;
}
.dashboard-div .inner-card.first .desc .color {
        font-weight: 600;
        text-transform: capitalize;
}
.dashboard-div .inner-card.first .desc .color.red,
.dashboard-div .inner-card.first .left-col .indicator-title.red {
        color: #DC2B2B;
}
.dashboard-div .inner-card.first .desc .color.green,
.dashboard-div .inner-card.first .left-col .indicator-title.green {
        color: #0AA677;
}
.dashboard-div .inner-card.first .desc .color.yellow,
.dashboard-div .inner-card.first .left-col .indicator-title.yellow {
        color: #CA8A04;
}

.dashboard-div .inner-card.first .left-col .title {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #374151;
}
.dashboard-div .inner-card.first .left-col .indicator-title {
        margin: 8px 0 40px 0;
        font-weight: 700;
        font-size: 21px;
        line-height: 100%;
        color: #374151;
        text-transform: capitalize;
}

.dashboard-div .inner-card.first .left-col .bar-div {
        height: 12px;
        background-color: #E8EAF0;
        border-radius: 100px;
        position: relative;
}
.dashboard-div .inner-card.first .left-col .bar-div .base {
        width: 100%;
        height: 100%;
        background-color: #E8EAF0;
        border-radius: 100px;
}

.dashboard-div .inner-card.first .left-col .bar-div .percent {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        border-radius: 100px;
}

.dashboard-div .inner-card.first .left-col .bar-div .percent.red {
        background-color: #DC2B2B;
}
.dashboard-div .inner-card.first .left-col .bar-div .percent.yellow {
        background-color: #CA8A04;
}
.dashboard-div .inner-card.first .left-col .bar-div .percent.green {
        background-color: #0AA677;
}

.dashboard-div .inner-card.first .left-col .bar-div .number {
        height: 40px;
        width: 40px;
        border-radius: 100%;
        box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 800;
        font-size: 16px;
        color: #374151;
        background-color: #FFFFFF;
        position: absolute;
        top: 0;
        transform: translate(-50%, -40%);
}
.dashboard-div .inner-card.first .left-col .bar-div .number span {
        font-size: 10px;
        color: #0B101B66;
}

.dashboard-div .inner-card.first .left-col p.info {
        margin-top: 32px;
        font-size: 14px;
        line-height: 20px;
        color: #144EE3;
        cursor: pointer;
}

.dashboard-div .inner-card.second .map-svg {
        margin: -50px 20px -50px -50px;
}
.dashboard-div .inner-card.second .map-svg .rsm-marker {
        cursor: pointer;
}
.dashboard-div .inner-card.second .map-svg .rsm-marker circle {
        fill: #144EE3;
}
.dashboard-div .inner-card.second .map-svg .rsm-marker text {
        font-weight: 500;
        color: #374151;
        display: none;
}

.dashboard-div .inner-card.second .map-svg .rsm-marker:hover text {
        display: inherit;
}

.dashboard-div .four-col-row .inner-card {
        transition: all 0.2s ease-in-out;
        cursor: pointer;
}
.dashboard-div .four-col-row .inner-card:hover {
        background-color: #144EE3;
}

.dashboard-div .four-col-row .inner-card:hover .label,
.dashboard-div .four-col-row .inner-card:hover .value {
        color: #FFFFFF !important;
}

.dashboard-div .four-col-row .inner-card .label {
        margin-bottom: 16px;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #374151;
        text-transform: capitalize;
}

.dashboard-div .four-col-row .inner-card .bottom-section {
        display: flex;
        align-items: center;
        justify-content: space-between;
}
.dashboard-div .four-col-row .inner-card .bottom-section .value {
        font-weight: 600;
        font-size: 21px;
        color: #374151;
}

.dashboard-div .four-col-row .inner-card .bottom-section .diff {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 4px 8px 4px 6px;
        gap: 4px;
        font-weight: 600;
        font-size: 12px;
        color: #374151;
        background: #E8EAF0;
        border-radius: 4px; 
}

.dashboard-div .four-col-row .inner-card .bottom-section .diff.detected {
        color: #F67906;
        background: #FFE7CD;
}
.dashboard-div .four-col-row .inner-card .bottom-section .diff.detected svg path {
        stroke: #F67906;
}
.dashboard-div .four-col-row .inner-card .bottom-section .diff.flagged {
        color: #DC2B2B;
        background: #fdeaec;
}
.dashboard-div .four-col-row .inner-card .bottom-section .diff.flagged svg path {
        stroke: #db2b2b
}
.dashboard-div .four-col-row .inner-card .bottom-section .diff.removed {
        color: #895302;
        background: #ECE6DC;
}
.dashboard-div .four-col-row .inner-card .bottom-section .diff.removed svg path {
        stroke: #895302
}
.dashboard-div .four-col-row .inner-card .bottom-section .diff.saved {
        color: #0AA677;
        background: #EEF9F6;
}

.dashboard-div .card-title {
        font-weight: 700;
        font-size: 18px;
        line-height: 150%;
        color: #374151;
        text-transform: capitalize;
}

.dashboard-div .inner-card .card-title {
        font-weight: 700;
        font-size: 18px;
        line-height: 150%;
        color: #374151;
        text-transform: capitalize;
}
.dashboard-div .inner-card .ant-select-selection-item {
        color: #144EE3;
}

.dashboard-div .inner-card .title-col {
        margin-bottom: 24px;
        display: flex;
        align-items: center;
        justify-content: space-between;
}

.dashboard-div .inner-card .title-col button {
        display: flex;
        align-items: center;
}

.dashboard-div .inner-card .title-col button svg {
        margin-left: 6px;
}
.dashboard-div .inner-card .title-col button:hover svg path {
        fill: #144EE3;
}

.dashboard-div .inner-card .table-col .type {
        padding: 16px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #E6E6E6;
}

.dashboard-div .inner-card .table-col .type:last-child {
        border-bottom: none;
}

.dashboard-div .inner-card .table-col .type .label {
        width: 100px;
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 14px;
        color: #374151;
        cursor: pointer;

}
.dashboard-div .inner-card .table-col .type .label .color {
        margin-right: 6px;
        height: 8px;
        width: 8px;
        background-color: #E8EAF0;
        border-radius: 100%;
}
.dashboard-div .inner-card .table-col .type .percent {
        width: 50px;
        text-align: end;
}
.dashboard-div .inner-card .table-col .type .value {
        word-break: break-all;
        max-width: 200px;
}

.dashboard-div .chart, .platform-modal .chart {
        padding: 8px 0 0 28px;
        display: flex;
        /* align-items: center; */
}
.dashboard-div .chart .map-div {
        height: 100%;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        /* justify-content: space-between; */
}
.dashboard-div .chart .map-div .marker-div {
        position: absolute;
        bottom: 0;
        width: 94%;
}
.dashboard-div .chart .map-div, .platform-modal .chart .map-div {
        width: 100%;
}
.dashboard-div .chart .map-div .map-item {
        margin-bottom: 24px;
}
.platform-modal .chart .map-div .map-item {
        margin-bottom: 16px;
}
.dashboard-div .chart .map-div .map-item .label, .platform-modal .chart .map-div .map-item .label {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #374151;
        text-transform: capitalize;
        display: flex;
        align-items: center;
        cursor: pointer;
}
.dashboard-div .chart .map-div .map-item .label .label-img, .platform-modal .chart .map-div .map-item .label .label-img {
        margin-right: 8px;
        width: 20px;
        height: 20px;
        border-radius: 4px;
}
.dashboard-div .chart .map-div .map-item .progress-base, .platform-modal .chart .map-div .map-item .progress-base {
        display: flex;
        align-items: center;
}
.dashboard-div .chart .map-div .map-item .progress-base .base-line, .platform-modal .chart .map-div .map-item .progress-base .base-line {
        width: 93.5%;
        border-radius: 100px;
        background-color: #E8EAF0;
}
.dashboard-div .chart .map-div .map-item .progress-base .line, .platform-modal .chart .map-div .map-item .progress-base .line {
        height: 8px;
        border-radius: 100px;
        background-color: #144EE3;
}
.dashboard-div .chart .map-div .map-item .progress-base .value, .platform-modal .chart .map-div .map-item .progress-base .value {
        margin-left: 8px;
        font-weight: 400;
        width: 60px;
        font-size: 14px;
        line-height: 20px;
        color: #374151;
}

.dashboard-div .inner-card.fourth {
        height: 435px;
}

.dashboard-div .inner-card.fourth .chart-col {
        display: flex;
        align-items: center;

}
.dashboard-div .inner-card.fourth .chart-col .category-list {
        padding-left: 30px;
}
.dashboard-div .inner-card.fourth .chart-col .category-list .category {
        margin-bottom: 40px;
}
.dashboard-div .inner-card.fourth .chart-col .category-list .category .label {
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #374151;
        cursor: pointer;
}
.dashboard-div .inner-card.fourth .chart-col .category-list .category .label .dot {
        margin-right: 6px;
        height: 8px;
        width: 8px;
        border-radius: 100%;
        background-color: #E6E6E6;
}
.dashboard-div .inner-card.fourth .chart-col .category-list .category .label .dot.marketplaces {
        background-color: #144EE3;
}
.dashboard-div .inner-card.fourth .chart-col .category-list .category .label .dot.social-media {
        background-color: #090E24;
}
.dashboard-div .inner-card.fourth .chart-col .category-list .category .label .dot.search-engine {
        background-color: #E8EAF0;
}
.dashboard-div .inner-card.fourth .chart-col .category-list .category .value {
        margin-left: 14px;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #374151;
}

.dashboard-div .inner-card.fifth .progress-bar-div .progress-base {
        margin: 16px 0 16px 0;
        width: 100%;
        height: 8px;
        border-radius: 100px;
        background-color: #E8EAF0;
        position: relative;
        overflow: hidden;
        top: 0;
        left: 0;
}

.dashboard-div .inner-card.fifth .progress-bar-div .progress-base .line {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
}

.dashboard-div .inner-card.fifth .progress-bar-div .progress-base .line.counterfeit {
        background-color: #144EE3;
}
.dashboard-div .inner-card.fifth .progress-bar-div .progress-base .line.replica {
        background-color: #090E24;
}
.dashboard-div .inner-card.fifth .progress-bar-div .progress-base .line.copyright {
        background-color: #60A5FA;
}
.dashboard-div .inner-card.fifth .progress-bar-div .progress-base .line.brand_abuse {
        background-color: #c4daf6;
}
.dashboard-div .inner-card.fifth .progress-bar-div .progress-base .line.other {
        background-color: #B3B9C8;
}


.platform-modal {
        top: 32px;
}
.platform-modal .ant-modal-body {
        padding-top: 20px;
        height: 75vh;
        overflow-y: scroll;
}
.platform-modal .ant-modal-body.right-col {
        height: 100%;
}
.platform-modal .chart {
        height: 100%;
}
.platform-modal .loading-div {
        height: 100% !important;
        display: flex;
        align-items: center;
        justify-content: center;
}

.platform-modal .chart .map-div .map-item .progress-base .value {
        width: 60px;
}

.country-card {
        box-shadow: 0px 6px 12px rgba(140, 152, 164, 0.075);
        overflow: hidden;
}
.country-card .label {
        margin-bottom: 8px;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #374151;
}
.country-card .value {
        font-weight: 600;
        font-size: 21px;
        color: #374151;
}
.country-card.clickable {
        cursor: pointer;
}
.country-card.clickable :hover {
        background-color: #144EE3;
}
.country-card.clickable :hover .label,
.country-card.clickable :hover .value {
        color: #FFFFFF !important;
}

.dashboard-div .markers {
        margin-top: 20px;
        padding-top: 6px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top: 1px solid #E6E6E6;
}
.dashboard-div .markers .marker {
        color:#9CA3AF;
        font-size: 12px;
        font-weight: 600;
        line-height: 16px;
}


/* mobile view */

@media screen and (max-width: 767px) {

        .dashboard-div .inner-card.first .left-col {
                padding: 0 0 16px 0;
                border: none;
                border-bottom: 1px dashed #D9D9D9;
        }
        .dashboard-div .inner-card.first .right-col {
                padding: 16px 0 0 0;
        }
        .dashboard-div .inner-card.first .right-col ul li svg {
                width: 20px;
                height: 20px;
        }
        .dashboard-div .inner-card.first .right-col ul li:last-child {
                margin-bottom: 0;
        }
        .dashboard-div .inner-card.second .map-svg {
                margin: -28px -4px -10px -21px;
        }
        .dashboard-div .chart, .platform-modal .chart {
                padding: 10px 0 0 0;
        }

        .dashboard-div .markers .marker {
                font-size: 10px;
        }

        .dashboard-div .inner-card.fourth .chart-col {
                flex-direction: column;
        }
        .dashboard-div .inner-card.fourth .chart-col .category-list {
                width: 100%;
                padding: 24px 0 0 0;
        }


        .filters-checkbox .ant-checkbox-wrapper.ant-checkbox-wrapper {
                width: 100%;
        }

        .rdrDateRangePickerWrapper {
                flex-direction: column;
                width: 100%;
        }
        .rdrDateRangePickerWrapper .rdrDefinedRangesWrapper {
                width: 100%;
        }
        .rdrDateRangePickerWrapper .rdrDefinedRangesWrapper .rdrMonth {
                width: 100%;
        }
}