.no-data-div {
        height: 50vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
}
.no-data-div .title {
        margin-top: 24px;
        color:#374151;
        font-size: 18px;
        font-weight: 600;
        line-height: 150%;
}
.no-data-div .desc {
        margin-top: 8px;
        color:#374151;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
}