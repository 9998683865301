.cases-card {
        min-height: calc(100vh - 48px);
}
.select-input-div {
        padding-left: 12px;
        border: 1px solid #E6E6E6;
        border-radius: 8px;
}

.select-input-div label {
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        text-align: right;
        letter-spacing: -0.1px;
        color: #0B101B;
}

.logo-img {
        width: 40px !important;
        height: 40px !important;
        margin-right: 12px;
        border: 1px solid rgba(125, 131, 152, 0.3);
        border-radius: 4px;
}
.no-logo-div {
        margin-right: 12px;
        height: 40px;
        width: 40px;
        background-color: #F8FAFD;
        border: 1px solid rgba(125, 131, 152, 0.3);
        border-radius: 4px;
}

/* mobile view */

@media screen and (max-width: 767px) {
        .clients-div .filter-space .ant-space-item {
                width: 100%;
        }
}