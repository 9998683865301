.notifications-content-card {
        margin: 0 auto;
        width: 700px;
}

.notifications-list {
        list-style: none
}

.notifications-list .notifications-list-item {
        display: flex;
        align-items: flex-start;
        border-bottom: 1px solid lightgray;
        padding: 20px 0;
    
}
.notifications-list .notifications-list-item .img-div .img {
        height: 64px;
        width: 64px;
        background: #f6f6f6;
        border-radius: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        font-weight: 500;
        text-transform: capitalize;
        color: #1f1f1f;
}
.notifications-list .notifications-list-item .text-div {
        margin-left: 24px;
}
.notifications-list .notifications-list-item .text-div .title {   
        font-size: 14px;
        font-weight: 500;
        line-height: 1.5;
        margin-bottom: 4px;
}
.notifications-list .notifications-list-item .text-div .date {   
        font-size: 12px;
        margin-top: 4px;
        color: #7b7b7b;
        font-style: italic;
        margin-bottom: 8px;
}
.notifications-list .notifications-list-item .marker-div {   
        margin-left: auto;
}
.notifications-list .notifications-list-item .marker-div .marker {   
        padding: 2px 8px;
        font-size: 12px;
        color: white;
        font-weight: 500;
        background: #FFFFFF;
        margin-left: auto;
        border-radius: 100px;
}
.notifications-list .notifications-list-item .marker-div .marker.new {
        background: #124ee3;
}

.notifications-list .link {
        color: #124ee3;
        cursor: pointer;
}


@media screen and (max-width: 767px) {
        .notifications-content-card {
                width: 100%;
        }
        .notifications-list .notifications-list-item {
                position: relative;
        }
        .notifications-list .notifications-list-item .img-div .img {
                height: 30px;
                width: 30px;
                font-size: 10px;
        }
        .notifications-list .notifications-list-item .marker-div {
                position: absolute;
                top: 4px;
                right: 4px;
        }
        .notifications-list .notifications-list-item .marker-div .marker {
                padding: 0px 6px;
                font-size: 10px;
        }
}
