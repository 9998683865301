.add-user-modal .usetype-radio-grp {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
}
.add-user-modal .usetype-radio-grp label {
        margin: 0;
        padding: 10px 16px;
        width: calc(50% - 12px);
        background-color: #F9FAFB;
        border: 1px solid #E5E7EB;
        border-radius: 8px;
}

.add-user-modal .usetype-radio-grp label.ant-radio-wrapper-checked {
        border: 2px solid #275CEF;
        background: #F0F3FF;
}
.add-user-modal .ant-form-item-explain-error {
        text-align: left;
}
.add-user-modal .ant-form-item .error-msg {
        margin: 4px 0 0 4px;
        color: red;
        text-align: left;
}


/* mobile view */

@media screen and (max-width: 767px) {
        .add-user-modal .usetype-radio-grp {
                flex-direction: column;
        }
        .add-user-modal .usetype-radio-grp label {
                width: 100%;
                margin-bottom: 16px;
        }
        .add-user-modal .usetype-radio-grp label:last-child {
                margin-bottom: 0;
        }
}