.profile-div .avatar-div {
    background: #e6e6e6;
    width: 140px;
    height: 140px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 32px;
    font-weight: 600;
    margin-top: 20px;
    text-transform: uppercase;
}