@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&display=swap');

* {
  font-family: 'Inter', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: #0B101B;
  background-image: url(./assets/images/bg.svg);
  background-size: cover;
  background-repeat: no-repeat;
}
.mobile-div {
  padding: 20px 0;
  background-color: #FFFFFF;
  border-radius: 6px;
  font-size: 16px;
  text-align: center;
  position: absolute;
  width: 95%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.card {
  border-radius: 14px;
}

button {
  box-shadow: none !important;
  border-radius: 8px !important;
  font-size: 14px !important;
}

.page-title {
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.2px;
  color: #0B101B;
}

.ant-table-thead .ant-table-cell {
  background-color: #F8FAFD !important;
  font-weight: 500 !important;
  font-size: 14px !important;
}
.ant-table-tbody .ant-table-cell {
  font-size: 14px !important;
  font-weight: 400 !important;
}

table th, table td {
  border-right: none !important;
  border-left: none !important;
}
table th:last-child, table td:last-child {
  border-right: 1px solid #f0f0f0 !important;
}

/* radio */
.ant-radio-inner {
  background-color: #FFFFFF !important;
  border-width: 1px !important;
  height: 16px !important;
  width: 16px !important;
}
.ant-radio-inner::after {
  transform: scale(0.5) !important;
  background: #144ee3 !important;
}

.ant-picker {
  padding: 0 12px;
}

.ant-picker input {
  box-shadow: none !important;
}

.ant-btn-primary:disabled {
  background-color: #144EE3;
  color: white;
  opacity: 0.5;
}

.ant-select-selection-item, .ant-picker-input input {
  font-size: 14px !important;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: grey;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: white;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 600;
}

/* filters */

.modal-title {
  margin: 0;
  margin-bottom: 8px;
  color:#374151;
  margin-bottom: 12px;
  font-size: 14px;
  font-weight: 600;
  line-height: 150%;
}

.filter-section {
  margin-bottom: 16px;
}

.filters-checkbox {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.filters-checkbox .ant-checkbox-wrapper {
  margin: 0;
  margin-bottom: 24px;
  padding: 16px 24px;
  width: calc(50% - 12px);
  border-radius: 8px;
  border: 1px solid var(--cool-gray-200, #E5E7EB);
  background: var(--cool-gray-50, #F9FAFB);
}
.filters-checkbox .ant-checkbox-wrapper.ant-checkbox-wrapper-checked {
  border-radius: 8px;
  border: 2px solid #275CEF;
  background: var(--indigo-50, #F0F3FF);
}

.filters-radio-group {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.filters-radio-group label {
  margin: 0;
  margin-bottom: 24px;
  padding: 16px 24px;
  color: var(--cool-gray-700, #374151);
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  border-radius: 8px;
  border: 1px solid var(--cool-gray-200, #E5E7EB);
  background: var(--cool-gray-50, #F9FAFB);
  width: calc(50% - 12px);
}
.filters-radio-group label.ant-radio-wrapper-checked {
  border-radius: 8px;
  border: 2px solid #275CEF;
  background: var(--indigo-50, #F0F3FF);
}

.ant-select-disabled .ant-select-selector {
  background-color: white !important;
  color: #374151 !important;
}

.ant-checkbox-wrapper-disabled span {
  color: #374151;
}
.ant-checkbox-wrapper-disabled .ant-checkbox-checked .ant-checkbox-inner {
  background-color: #144ee3 !important;
  border-color: #144ee3 !important;
}
.ant-checkbox-wrapper-disabled .ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: #FFFFFF !important
}



/* status tag color */

.status.pending {
  color: #F67906 !important;
  background-color: #FFE7CD !important;
}
.status.notified {
  color: #C0379F !important;
  background-color: #FFE8F9 !important;
}
.status.review {
  color: #144EE3 !important;
  background-color: #DFEEFF !important;
}
.status.verified {
  color: #0B754F !important;
  background-color: #D0FFD5 !important;
}
.status.authorized {
  color: #12828F !important;
  background-color: #C7F9FF !important;
}
.status.reported {
  color: #B38803 !important;
  background-color: #FBF5BA !important;
}
.status.removed {
  color: #895302 !important;
  background-color: #ECE6DC !important;
}
.status.unauthorized {
  color: #CF182E !important;
  background-color: #FFEBEC !important;
}
.status.new-seller {
  color: #4944D4 !important;
  background-color: #E2E1FF !important;
}


.ant-pagination-options {
  display: none !important;
}