.seller-details-card {
        background-color: #f6f6f6;
        overflow: hidden;
}
.seller-details-card .ant-card-body {
        padding: 0;
}

.seller-details-card .first-section-row {
        padding: 24px 24px 0 24px;
}

.seller-details-card .left-col .ant-card-body {
        padding: 32px;
}

.seller-details-card .right-col .ant-card {
        margin-bottom: 24px;
}

.seller-details-card .right-col .ant-card-body {
        padding: 16px;
}

.seller-details-card .first-section-row .left-col .img-placeholder, .modal-top-info-div .img-placeholder {
        height: 80px;
        width: 80px;
        background-color: #DFDFDF;
        border-radius: 100%;
}

.modal-top-info-div {
        position: sticky;
        top: 0;
        background-color: #FFFFFF;
        z-index: 1000;
}

.seller-details-card .first-section-row .left-col .top-div, .modal-top-info-div .top-div {
        margin-bottom: 24px;
        padding-bottom: 24px;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        border-bottom: 1px solid #E5E7EB;
}

.modal-top-info-div .top-div {
        border: none;
        margin: 0;
        margin-left: 8px;
        padding: 0;
}

.seller-details-card .first-section-row .left-col .top-div .title, .modal-top-info-div .top-div .title {
        color: #0B101B;
        font-size: 28px;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: -0.2px;
        text-transform: capitalize;
}

.seller-details-card .first-section-row .left-col .top-div .desc-div, .modal-top-info-div .top-div .desc-div {
        margin-top: 12px;
        display: flex;
        align-items: center;
}
.seller-details-card .status, .modal-top-info-div .status {
        margin-right: 8px;
        padding: 4px 12px;
        font-size: 14px;
        font-weight: 500;
        color: #374151;
        background-color: #E5E7EB;
        border-radius: 40px;
        text-transform: capitalize;
        text-align: center;
}

.seller-details-card .first-section-row .left-col .top-div .desc-div .last-update, .modal-top-info-div .top-div .desc-div .last-update {
        color: #6B7280;
        font-size: 14px;
        line-height: 20px;
}

.seller-details-card .first-section-row .left-col .bottom-div .platform {
        margin-bottom: 16px;
        color:#374151;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
}
.seller-details-card .first-section-row .left-col .bottom-div .platform span {
        font-weight: 500;
}

.seller-details-card .first-section-row .left-col .bottom-div ul.meta {
        list-style: none;
}

.seller-details-card .first-section-row .left-col .bottom-div ul.meta li {
        margin-bottom: 16px;
        display: flex;
        align-items: center;
        color: #374151;
        font-size: 14px;
        line-height: 20px;
}
.seller-details-card .first-section-row .left-col .bottom-div ul.meta li svg {
        margin-right: 8px;
}
.seller-details-card .first-section-row .left-col .bottom-div .link {
        margin-top: 8px;
        display: flex;
        align-items: center;
        color: #144EE3;
        font-size: 14px;
        line-height: 20px;
        cursor: pointer;
}
.seller-details-card .first-section-row .left-col .bottom-div .link svg {
        margin-left: 8px;
}

.seller-details-card .first-section-row .right-col .label {
        margin-bottom: 4px;
        color: #374151;
        font-size: 16px;
        font-weight: 500;
        line-height: 150%;
}

.seller-details-card .first-section-row .right-col .value {
        color: #374151;
        font-size: 24px;
        font-weight: 600;
        line-height: 40px;
}

.seller-details-card .second-section-div {
        padding: 16px 24px 24px 24px;
}

.seller-details-card .second-section-div hr {
        margin-bottom: 40px;
        border: none;
        height: 1px;
        background-color: #E5E7EB;
}

.seller-details-card .second-section-div .card-title {
        margin-bottom: 16px;
        color:#0B101B;
        font-size: 24px;
        font-weight: 700;
        line-height: 32px;
        letter-spacing: -0.2px;
}
.seller-details-card .second-section-div .card-title .number {
        color: #0B101B99;
        font-size: 14px;
}

.seller-details-card .listing-title-cell {
        display: flex;
        align-items: center;
}
.seller-details-card .listing-title-cell img {
        margin-right: 10px
}

.seller-details-modal label {
        color:#6B7280 !important;
        font-weight: 400 !important;
}

.seller-details-modal .logo-input-div {
        display: flex;
        align-items: center;
}

.seller-details-modal .logo-input-div .link {
        margin-left: 8px;
        position: absolute;
        right: -100%;
        top: 3px;
}

.logo-input-div .ant-upload-wrapper {
        display: flex;
        align-items: center;
}

.logo-input-div .ant-upload-list-item-container {
        margin-left: 16px;
        border: 1.5px dashed #d9d9d9;
        border-radius: 6px;
        padding: 3px 10px;
        font-weight: 500;
}
.logo-input-div .ant-upload-list-item-container .ant-upload-icon,
.logo-input-div .ant-upload-list-item-container .ant-upload-list-item-progress {
        display: none;
}
.logo-input-div .ant-upload-list-item {
        margin-top: 0 !important;
}

/* mobile view */

@media screen and (max-width: 767px) {
        .seller-details-card .first-section-row .left-col .top-div, .modal-top-info-div .top-div {
                flex-direction: column;
        }
        .seller-details-card .first-section-row .left-col .top-div .title, .modal-top-info-div .top-div .title {
                margin-top: 16px;
        }
        .seller-details-card .first-section-row .left-col .top-div .desc-div, .modal-top-info-div .top-div .desc-div {
                flex-direction: column;
                align-items: flex-start;
        }
        .modal-top-info-div .img-placeholder {
                width: 40px;
                height: 40px;
                margin-top: 16px;
        }
}