.test-purchase-div .tag {
        margin: 0 auto;
        padding: 4px 12px;
        border-radius: 40px;
        color: #144EE3;
        font-size: 14px;
        font-weight: 500;
        background-color: #DBEAFE;  
        text-transform: capitalize;  
        width: fit-content; 
}
.test-purchase-div .tag.cancelled {
        color: #DD2C2C;
        background-color: #DD2C2C1A;
}
.test-purchase-div .tag.delivered {
        color: #11845B;
        background-color: #DEF2E6;
}

.listing-details-modal .ant-modal-body {
        overflow-x: hidden;
}